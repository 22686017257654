import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
// import { Modal } from "react-bootstrap";
import GifImg from "../../assets/images/loader.gif";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BsFillTrashFill, BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import ModalBox from "../Widget/ModalBox";

const ManageDistrict = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //get state
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const modalClose = () => {
    setIsModalShow(false);
    reset({
      state: "",
      district_name: "",
      district_code: "",
      status: "",
    });
    setCityUpdate({ id: "", isUpdating: false });
  };
  const modalShow = () => setIsModalShow(true);

  const deleteModalClose = () => {
    setIsDeleteModalShow(false);
    setDeleteId("");
  };
  const deleteModalShow = (id) => {
    setDeleteId(id);
    setIsDeleteModalShow(true);
  };

  //get state
  const getStates = async () => {
    setIsLoading(true);
    const res = await Api("get-state");
    // console.log(res);
    if (res?.data?.status === 1) {
      setStateList(res?.data?.states);
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get state

  //get Cities
  const getCities = async () => {
    setIsLoading(true);
    const res = await Api("get-city");
    // console.log(res);
    if (res?.data?.status === 1) {
      setCityList(res?.data?.districts.reverse());
      setIsLoading(false);
      setIsDataLoaded(true);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStates();
    getCities();
  }, []);
  //get Cities

  //is updating
  const [cityUpdate, setCityUpdate] = useState({
    id: "",
    isUpdating: false,
  });

  //Update City
  const editCity = (cityObj) => {
    modalShow();
    // console.log(cityObj);
    setCityUpdate({ id: cityObj.id, isUpdating: true });
    reset({
      state: cityObj.state_id,
      district_name: cityObj.district_name,
      district_code: cityObj.district_code,
      status: cityObj.status,
    });
  };
  //Update City

  //Delete City
  const removeCity = async () => {
    try {
      setIsDataLoaded(false);
      const body = {
        id: deleteId,
      };

      const res = await Api("delete-city", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        getCities();
        setDeleteId("");
        deleteModalClose();
        setIsLoading(false);
        toast.success("District Deleted Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete City

  const onSubmit = async (d) => {
    setIsLoading(true);
    // const { CityName, CityCode, Status } = data;
    // console.log(addCity);
    if (cityUpdate.isUpdating) {
      const body = {
        district_name: d.district_name,
        district_code: d.district_code,
        state_id: d.state,
        status: d.status,
      };
      const res = await Api(`update-city/${cityUpdate.id}`, "POST", body);
      // console.log(res);
      if (res?.data?.status === 1) {
        toast.success("District Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCityUpdate({
          id: "",
          isUpdating: false,
        });
        reset({
          state: 1,
          district_name: "",
          district_code: "",
          status: "",
        });
        getCities();
        setIsLoading(false);
        setIsDataLoaded(false);

        modalClose();
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } else {
      // console.log(d);
      const body = {
        district_name: d.district_name,
        district_code: d.district_code,
        state_id: d.state,
        status: d.status,
      };
      const res = await Api("add-city", "POST", body);
      if (res?.data?.status === 1) {
        toast.success("District Added Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        getCities();
        setIsLoading(false);
        setIsDataLoaded(false);

        modalClose();
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    }
  };
  //add & Update City

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-sm-flex text-center text-sm-start justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage Districts</h5>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={modalShow}
            >
              <BsPlusLg className="me-2" />
              Add District
            </button>
          </div>
        </div>
        <div>
          {isDataLoaded ? (
            cityList.length > 0 ? (
              <div className="table-responsive mt-3">
                <table className="table table-bordered m-0">
                  <thead>
                    <tr>
                      <th>#</th>

                      {/* <th>State Name</th> */}
                      <th>District Name</th>
                      <th>District Code</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cityList.map((item, i) => {
                      return (
                        <tr key={item.id}>
                          <td>{i + 1}</td>

                          {/* <td>{item?.state?.state}</td> */}
                          <td>{item?.district_name}</td>
                          <td>{item?.district_code}</td>
                          <td>
                            {item?.status === "Active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : item.status === "Deactive" ? (
                              <span className="badge bg-danger">Deactive</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => editCity(item)}
                              className="btn btn-primary btn-sm"
                            >
                              <BsFillPencilFill /> Edit
                            </button>
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              onClick={() => deleteModalShow(item?.id)}
                              className="btn btn-danger ms-1 btn-sm"
                            >
                              <BsFillTrashFill /> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Cities Added</p>
              </div>
            )
          ) : (
            <div className="loaderbox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
      <ModalBox
        show={isDeleteModalShow}
        hide={deleteModalClose}
        title="Delete District"
      >
        <div>
          <div className="mb-3">
            <p className="h5 text-dark">
              Are you sure you want to delete this district?
            </p>
          </div>
          <div className="d-flex gap-2">
            <button
              onClick={removeCity}
              type="button"
              className="btn btn-danger"
              disabled={isLoading}
            >
              Delete
            </button>
            <button
              onClick={deleteModalClose}
              type="button"
              className="btn btn-secondary"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBox>
      <ModalBox
        show={isModalShow}
        hide={modalClose}
        title={cityUpdate.isUpdating ? "Edit District" : " Add District"}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-12">
                <label className="form-label">Select State</label>
                <select
                  className="form-select"
                  disabled={isLoading}
                  {...register("state", { required: true })}
                >
                  <option value="">Select State</option>
                  {stateList
                    .filter((states) => {
                      return states.status === "Active";
                    })
                    .map((states) => {
                      return (
                        <option value={states.id} key={states.id}>
                          {states.state_name}
                        </option>
                      );
                    })}
                </select>
                {errors.state && (
                  <div className="error_line">State Name is required</div>
                )}
              </div>
              <div className="col-12">
                <label className="form-label">District Name</label>
                <input
                  type="text"
                  disabled={isLoading}
                  className="form-control"
                  placeholder="Enter District Name"
                  {...register("district_name", { required: true })}
                />
                {errors.district_name && (
                  <div className="error_line">District Name is required</div>
                )}
              </div>
              <div className="col-sm-6">
                <label className="form-label">District Code</label>
                <input
                  type="text"
                  disabled={isLoading}
                  className="form-control"
                  placeholder="Enter District Code"
                  {...register("district_code", { required: true })}
                />
                {errors.district_code && (
                  <div className="error_line">District Code is required</div>
                )}
              </div>
              <div className="col-sm-6  ">
                <label className="form-label">Status</label>
                <select
                  disabled={isLoading}
                  className="form-select"
                  {...register("status", { required: true })}
                >
                  <option value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                </select>
                {errors.status && (
                  <div className="error_line">Status is required</div>
                )}
              </div>

              <div className="col-12">
                <div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {cityUpdate.isUpdating ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
    </>
  );
};

export default ManageDistrict;
