import React from "react";
import {
  BiDesktop,
  BiDownload,
  BiStore,
  BiCheckShield,
  BiUser,
  BiMap,
  BiHistory
} from "react-icons/bi";
import { FaRegMap } from "react-icons/fa";
import { BiCog } from "react-icons/bi";

const useMenus = (permission) => {
  let accessMenus = [];

  accessMenus.push({
    path: "/dashboard",
    name: "Dashboard",
    icon: <BiDesktop />,
  });

  if (permission.includes("Download Form")) {
    accessMenus.push({
      path: "/download-form",
      name: "Downloaded Forms",
      icon: <BiDownload />,
    });
  }

  if (
    permission.includes("Manage Roles") ||
    permission.includes("Manage Users")
  ) {
    accessMenus.push({
      // path: "/file-manager",
      name: "Users",
      icon: <BiUser />,
      subRoutes: [],
    });
  }

  if (permission.includes("Manage Roles")) {
    let index = accessMenus.findIndex((a) => a.name === "Users");
    accessMenus[index].subRoutes.push({
      path: "/manage-role",
      name: "Manage Roles",
      icon: <BiCheckShield />,
    });
  }

  if (permission.includes("Manage Users")) {
    let index = accessMenus.findIndex((a) => a.name === "Users");
    accessMenus[index].subRoutes.push({
      path: "/manage-user",
      name: "Manage Users",
      icon: <BiUser />,
    });
  }

  if (
    permission.includes("Manage Centers") ||
    permission.includes("Manage States") ||
    permission.includes("Manage Districts") ||
    permission.includes("Manage Blocks")
  ) {
    accessMenus.push({
      // path: "/file-manager",
      name: "Masters",
      icon: <BiCog />,
      subRoutes: [],
    });
  }

  if (permission.includes("Manage Centers")) {
    let index = accessMenus.findIndex((a) => a.name === "Masters");
    accessMenus[index].subRoutes.push({
      path: "/manage-center",
      name: "Manage Centers",
      icon: <BiStore />,
    });
  }

  if (permission.includes("Manage States")) {
    let index = accessMenus.findIndex((a) => a.name === "Masters");
    accessMenus[index].subRoutes.push({
      path: "/manage-state",
      name: "Manage States",
      icon: <BiMap />,
    });
  }

  if (permission.includes("Manage Districts")) {
    let index = accessMenus.findIndex((a) => a.name === "Masters");
    accessMenus[index].subRoutes.push({
      path: "/manage-district",
      name: "Manage Districts",
      icon: <FaRegMap />,
    });
  }

  // if (permission.includes("Manage Blocks")) {
  //   let index = accessMenus.findIndex((a) => a.name === "Masters");
  //   accessMenus[index].subRoutes.push({
  //     path: "/manage-block",
  //     name: "Manage Blocks",
  //     icon: <BsPinMap />,
  //   });
  // }

  if (permission.includes("Payment History")) {
    accessMenus.push({
      path: "/payment-history",
      name: "Payment History",
      icon: <BiHistory />,
    });
  }

  return accessMenus;
};

export default useMenus;
