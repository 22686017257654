import React from "react";
import { Link } from "react-router-dom";
import ErrorImg from "../../assets/images/404.jpg";

const index = () => {
  return (
    <>
      <section className="missingpage">
        <Link to="/" className="d-block">
          <img
            src={ErrorImg}
            alt="404"
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          />
        </Link>
      </section>
    </>
  );
};

export default index;
