import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import useRoute from "./useRoute";

import Auth from "../Navigation/Auth";
import Login from "../Views/Login";
import Layout from "../Views/Components/Layout";
import Dashboard from "../Views/Dashboard";
import ChangeInformation from "../Views/ChangeInformation";
import ChangePassword from "../Views/ChangePassword";
import Missing from "../Views/Missing";

const Routing = () => {
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const isLoggedIn = useSelector((state) => state.authentication.isAdmin);
  const permissions = isLoggedIn ? admin?.role?.permission : null;
  const { accessRoutes } = useRoute(permissions);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route index element={<Login />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route element={<Auth redirectPath="/" />}>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            {accessRoutes}
            <Route path="change-information" element={<ChangeInformation />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
        </Route>
        <Route path="*" element={<Missing />} />
      </Routes>
    </>
  );
};

export default Routing;
