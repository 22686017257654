import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import Table from "../Widget/Table";
import Loader from "../Widget/Loader";
import dayjs from "dayjs";
import TooltipWidget from "../Widget/TooltipWidget";
import { BsFillEyeFill } from "react-icons/bs";
import ModalBox from '../Widget/ModalBox'
const PaymentHistory = () => {
  const [application, setApplication] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isdetailModalShow, setIsdetailModalShow] = useState(false);
  const [detailModalData, setDetailModalData] = useState("");

  const detailModalShow = (d) => {
    setDetailModalData(d);
    setIsdetailModalShow(true);
  };
  const detailModalClose = () => {
    setDetailModalData("");
    setIsdetailModalShow(false);
  };
  //get getDownloadedList
  const getDownloadedList = async () => {
    const res = await Api("payment_history");
    // console.log(res);
    if (res?.data?.status === 1) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setApplication(res?.data?.data);
      setIsDataLoaded(true);
      // setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // setIsLoading(false);
    }
  };
  //get getDownloadedList

  useEffect(() => {
    getDownloadedList();
  }, []);

  // const caseInsensitiveSort = (a, b) =>  a?.amount - b?.amount;


  const columns = [
    {
      name: "#",
      selector: (props) => application.indexOf(props) + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "Application ID",
      selector: (row) => (!!row.application_no ? row.application_no : "-"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment Type",
      selector: (row) =>
        !!row.application?.payment_mode ? row.application?.payment_mode : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment Type Other",
      selector: (row) =>
        !!row.application?.payment_type_other
          ? row.application?.payment_type_other
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      wrap: true,
      sortFunction: (a, b) => a?.amount - b?.amount,
    },
    {
      name: "Reference ID",
      selector: (row) => row.reference_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Payout ID",
      selector: (row) => row.payout_id,
      sortable: true,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => dayjs(row.created_at).format("DD MMM YYYY hh:mm A"),
      sortable: true,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <>
            <div>
              <TooltipWidget title="View">
                <button
                  className="btn btn-warning btn-sm"
                  type="button"
                  onClick={() => detailModalShow(row)}
                >
                  <BsFillEyeFill />
                </button>
              </TooltipWidget>
            </div>
          </>
        );
      },
      sortable: true,
      wrap: true,
      width: "70px",
    },
  ];

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-md-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Payment History</h5>
          </div>
        </div>

        <div className="mt-3">
          {isDataLoaded ? (
            <Table columns={columns} data={application} />
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <ModalBox
        show={isdetailModalShow}
        hide={detailModalClose}
        title="Payment Detail"
        size="lg"
      >
        <div>
          <div className="row g-2">
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Application ID</label>
                <p>{detailModalData?.application_id}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Name</label>
                <p>{detailModalData?.account_holder_name}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Email</label>
                <p>{detailModalData?.email}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Contact Number</label>
                <p>{detailModalData?.contact}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Amount</label>
                <p>{detailModalData?.amount}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Account Number</label>
                <p>{detailModalData?.account_no}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">IFSC</label>
                <p>{detailModalData?.ifsc}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Payment Type</label>
                <p>
                  {!!detailModalData?.application?.payment_mode
                    ? detailModalData?.application?.payment_mode
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Payment Type Other</label>
                <p>
                  {!!detailModalData?.application?.payment_type_other
                    ? detailModalData?.application?.payment_type_other
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Reference ID</label>
                <p>{detailModalData?.reference_id}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Payout ID</label>
                <p>{detailModalData?.payout_id}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Status</label>
                <p>{detailModalData?.status}</p>
              </div>
            </div>
            <div className="col-6 col-lg-4">
              <div className="px-3 py-2 bg-light rounded">
                <label className="small fw-bold mb-1">Date</label>
                <p>
                  {dayjs(detailModalData?.created_at).format(
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    </>
  );
};

export default PaymentHistory;
