import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Reducers/Authentication";
import Logo from "../../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LoginImg from "../../assets/images/loginbg.jpg";

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isAdmin = useSelector((state) => state.authentication.isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAdmin === true) {
      navigate("/dashboard");
    }
  }, [isAdmin, navigate]);

  //Login Submit Function
  const onSubmit = async (data) => {
    setIsLoading(true);
    const body = {
      email: data.email,
      password: data.password,
    };
    // console.log(body);
    const res = await Api("user-login", "POST", body);
    // console.log(res);

    if (res.data.status === 1) {
      dispatch(setAuth(res.data.UserDetails));
      navigate("/dashboard");
      toast.success("Login Successful", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="homeloginsection">
        <div className="col-xl-6 col-lg-8 col-md-10 col-sm-11 col-11">
          <div className="homeloginbox">
            <div className="row g-0 h-100">
              <div className="col-sm-6 d-none d-sm-block">
                <div className="homeloginimg">
                  <img src={LoginImg} alt="login" className="w-100" />
                </div>
              </div>
              <div className="col-sm-6 align-self-center">
                <div className="p-3 p-lg-4">
                  <div className="homelogo">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <h5 className="text-center mb-4 text-dark">Login</h5>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="form-label">Username</label>
                          <input
                            type="text"
                            placeholder="Username"
                            disabled={isLoading}
                            {...register("email", {
                              required: "Username is required",
                            })}
                          />
                          {errors.email && (
                            <div className="error_line">
                              {errors.email.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="form-label">Password</label>
                          <input
                            type="password"
                            placeholder="Password"
                            disabled={isLoading}
                            {...register("password", {
                              required: "Password is required",
                            })}
                          />
                          {errors.password && (
                            <div className="error_line">
                              {errors.password.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "Login"}
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-12 text-center mt-4 homeloginfooter">
                        <p>
                          Copyright © NSDMA Admin. All Rights Reserved{" "}
                          {new Date().getFullYear()}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
