import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import GifImg from "../../assets/images/loader.gif";
import { Link, useNavigate } from "react-router-dom";
import { BsPlusLg, BsFillPencilFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ManageCenter = () => {
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const [stateList, setStateList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [cityList, setCityList] = useState([]);
  // const [blockList, setBlockList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //get centers
  const getCenters = async () => {
    const res = await Api("get-center");
    // console.log(res);
    if (res.data.status === 1) {
      setCenterList(res.data.data.reverse());
      // setCenterFilterList(res.data);
      setIsDataLoaded(true);
    } else {
      setIsDataLoaded(true);
    }
  };

  const editCenter = (d) => {
    navigate("/add-center", { state: { data: d } });
  };
  //get state
  const getStates = async () => {
    setIsLoading(true);
    const res = await Api("get-state");
    // console.log(res);
    if (res?.data?.status === 1) {
      setStateList(res?.data?.states);
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get state
  //get Cities
  const getCities = async (id) => {
    setIsLoading(true);
    const res = await Api(`get-city/${id}`);
    // console.log(res);
    if (res?.data?.status === 1) {
      setCityList(res?.data?.districts);
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get cities

  //get Blocks
  // const getBlocks = async (id) => {
  //   setIsLoading(true);
  //   const res = await Api(`get-block/${id}`);
  //   if (res?.data?.status === 1) {
  //     setBlockList(res.data.data);
  //     setIsLoading(false);
  //     return true;
  //   } else {
  //     toast.error(res?.data?.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //     setIsLoading(false);
  //     return true;
  //   }
  // };
  //get Blocks

  const inputChange = async (e) => {
    if (e.target.value) {
      if (e.target.name === "state") {
        getCities(e.target.value);
        reset({
          district: "",
          block: "",
        });
      }
      // else if (e.target.name === "district") {
      //   getBlocks(e.target.value);
      //   setValue("block", "");
      // }
    }
  };

  useEffect(() => {
    getStates();
    getCenters();
  }, []);

  const onSubmit = async (d) => {
    if (d.state !== "" || d.district !== "" || d.status !== "") {
      setIsLoading(true);
      const body = {
        state_id: "1",
        city_id: d.district,
        status: d.status,
      };
      const res = await Api("center-search", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        setCenterList(res.data.data);
        // reset();
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } else {
      toast.warning("Please select atleast one filter!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-sm-flex text-center text-sm-start justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage Centers</h5>
          </div>
          <div className="flex-shrink-0">
            <Link to="/add-center" className="btn btn-primary btn-sm">
              <BsPlusLg className="me-2" />
              Add Center
            </Link>
          </div>
        </div>
        <div>
          <form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-lg">
                <select
                  className="form-select"
                  {...register("state", {
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select State</option>
                  {stateList
                    .filter((state) => {
                      return state.status === "Active";
                    })
                    .map((state) => {
                      return (
                        <option value={state.id} key={state.id}>
                          {state.state_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-lg">
                <select
                  className="form-select"
                  {...register("district", {
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select District</option>
                  {cityList
                    .filter((cities) => {
                      return cities.status === "Active";
                    })
                    .map((cities) => {
                      return (
                        <option value={cities.id} key={cities.id}>
                          {cities.district_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              {/* <div className="col-lg">
                <select
                  className="form-select"
                  {...register("block")}
                  disabled={isLoading}
                >
                  <option value="">Select Block</option>
                  {blockList
                    .filter((blocks) => {
                      return blocks.status === "Active";
                    })
                    .map((blocks) => {
                      return (
                        <option value={blocks.id} key={blocks.id}>
                          {blocks.block}
                        </option>
                      );
                    })}
                </select>
              </div> */}
              <div className="col-lg">
                <select
                  className="form-select"
                  {...register("status")}
                  disabled={isLoading}
                >
                  <option value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                </select>
              </div>
              <div className="col-lg-1">
                <button type="submit" className="btn btn-primary w-100">
                  Filter
                </button>
              </div>
            </div>
          </form>
        </div>
        {isDataLoaded ? (
          centerList.length > 0 ? (
            <div>
              <div className="">
                <div className="row g-3">
                  <div className="col-12">
                    <table className="table table-bordered small m-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Center</th>
                          <th>Authorised Person</th>
                          <th>Address</th>
                          <th>Username</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {centerList?.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {data?.center_name}
                                <br />
                                {data?.center_email}
                              </td>
                              <td>
                                {data?.name}
                                <br />
                                {data?.phone}
                                <br />
                                {data?.email}
                              </td>
                              <td>
                                {data?.address}
                                <br />
                                {data?.city?.district_name}
                                
                              </td>
                              <td>{data?.username}</td>
                              <td>
                                {data?.status === "Active" ? (
                                  <span className="badge bg-success">
                                    Active
                                  </span>
                                ) : data?.status === "Deactive" ? (
                                  <span className="badge bg-danger">
                                    Deactive
                                  </span>
                                ) : null}
                              </td>
                              <td>
                                <button
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Edit"
                                  onClick={() => editCenter(data)}
                                  className="btn btn-primary btn-sm"
                                >
                                  <BsFillPencilFill /> Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="alert alert-danger m-0">
              <p>No Centers Found</p>
            </div>
          )
        ) : (
          <div className="loaderbox">
            <img src={GifImg} alt="Loader" />
            <p>Loading...</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ManageCenter;
