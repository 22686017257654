import React, { useState, useEffect, useRef } from "react";
import Api from "../../config/Api";
import GifImg from "../../assets/images/loader.gif";
// import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import ModalBox from "../Widget/ModalBox";

const ManageUser = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const [blockList, setBlockList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [userUpdate, setUserUpdate] = useState({
    id: "",
    isUpdating: false,
  });

  const modalClose = () => {
    reset({
      name: "",
      email_id: "",
      phone: "",
      status: "",
      state: "1",
      city: "",
      block: "",
      role: "",
      password: "",
    });
    setIsModalShow(false);
    setUserUpdate({ id: "", isUpdating: false });
  };
  const modalShow = () => setIsModalShow(true);

  const deleteModalClose = () => {
    setIsDeleteModalShow(false);
    setDeleteId("");
  };
  const deleteModalShow = (id) => {
    setDeleteId(id);
    setIsDeleteModalShow(true);
  };

  //get users
  const getUsers = async () => {
    setIsLoading(true);
    const res = await Api("get-user");
    // console.log(res);
    if (res.data.status === "1") {
      setUserList(res.data.userData.reverse());
      setIsLoading(false);
      setIsDataLoaded(true);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get users

  //get roles
  const getRoles = async () => {
    setIsLoading(true);
    const res = await Api("get-role");
    if (res?.data?.status === 1) {
      setRoleList(res?.data?.roleDetail);
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get roles

  //get state
  const getStates = async () => {
    setIsLoading(true);
    const res = await Api("get-state");
    // console.log(res);
    if (res?.data?.status === 1) {
      setStateList(res?.data?.states);
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get state

  //get Cities
  const getCities = async (id) => {
    setIsLoading(true);
    const res = await Api(`get-city/${id}`);
    // console.log(res);
    if (res?.data?.status === 1) {
      setCityList(res?.data?.districts);
      setIsLoading(false);
      return true;
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get cities

  //get Blocks
  // const getBlocks = async (id) => {
  //   setIsLoading(true);
  //   const res = await Api(`get-block/${id}`);
  //   if (res?.data?.status === 1) {
  //     setBlockList(res.data.data);
  //     setIsLoading(false);
  //     return true;
  //   } else {
  //     toast.error(res?.data?.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //     setIsLoading(false);
  //     return true;
  //   }
  // };
  //get Blocks

  const inputChange = async (e) => {
    if (e.target.value) {
      if (e.target.name === "state") {
        getCities(e.target.value);
        reset({
          ...getValues(),
          district: "",
          block: "",
        });
      } else if (e.target.name === "district") {
        // getBlocks(e.target.value);
        setValue("block", "");
      }
    }
  };
  //get Block

  //Update User
  const editUser = async (userObj) => {
    // console.log(userObj);
    setUserUpdate({
      id: userObj.id,
      isUpdating: true,
    });
    modalShow();
    const cityres = await getCities(userObj.state?.id);
    // const blockres = await getBlocks(userObj.city?.id);
    if (cityres) {
      reset({
        name: userObj.full_name,
        email_id: userObj.email_id,
        phone: userObj.mobile_no,
        status: userObj.status,
        state: userObj.state?.id,
        district: userObj.city?.id,
        // block: userObj.block?.id,
        role: userObj.role?.id,
      });
    }
  };
  //Update User

  const divref = useRef(null);
  //Delete User
  const removeUser = async () => {
    try {
      setIsLoading(true);
      const body = {
        id: deleteId,
      };
      const res = await Api("delete-user", "POST", body);
      if (res.data.status === 1) {
        toast.success("User Deleted Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        getUsers();
        setDeleteId("");
        deleteModalClose();
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete User

  useEffect(() => {
    getUsers();
    getRoles();
    getStates();
    getCities(1);
  }, []);

  const onSubmit = async (d) => {
    setIsLoading(true);
    if (userUpdate.isUpdating) {
      let body = {
        name: d.name,
        email_id: d.email_id,
        phone: d.phone,
        status: Number(d.status),
        state_id: d.state,
        district_id: d.district,
        // block_id: d.block,
        role_id: d.role,
        password: d.password,
      };

      // let bodynew = Object.entries(body).reduce(
      //   (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      //   {}
      // );

      Object.keys(body).forEach((key) => {
        if (body[key] === "") {
          delete body[key];
        }
      });

      // console.log(body);

      const res = await Api(`update-user/${userUpdate.id}`, "POST", body);
      if (res.data.status === 1) {
        toast.success("User Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setUserUpdate({
          id: "",
          isUpdating: false,
        });
        reset();
        getUsers();
        modalClose();
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } else {
      const body = {
        name: d.name,
        email_id: d.email_id,
        phone: d.phone,
        password: d.password,
        status: Number(d.status),
        state_id: d.state,
        district_id: d.district,
        block_id: d.block,
        role_id: d.role,
      };
      // console.log(body);
      const res = await Api("add-user", "POST", body);
      if (res.data.status === 1) {
        toast.success("User Added Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        modalClose();
        getUsers();
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-sm-flex text-center text-sm-start justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage Users</h5>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={modalShow}
            >
              <BsPlusLg className="me-2" />
              Add User
            </button>
          </div>
        </div>
        <div>
          {isDataLoaded ? (
            userList.length > 0 ? (
              <div className="table-responsive" ref={divref}>
                <table className="table table-sm small table-bordered m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role Name</th>
                      <th>Detail</th>
                      <th>Location</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((item, i) => {
                      return (
                        <tr key={item.id}>
                          <td>{i + 1}</td>
                          <td>{item?.role?.role_name}</td>

                          <td>
                            {item?.full_name}
                            <br />
                            {item?.email_id}
                            <br />
                            {item?.mobile_no}
                          </td>
                          <td>
                            {/* {item?.block?.block}
                            <br /> */}
                            {item?.city?.district_name}
                            <br />
                            {item?.state?.state_name}
                          </td>
                          <td>
                            {item.status === "1" ? (
                              <span className="badge bg-success">Active</span>
                            ) : item.status === "0" ? (
                              <span className="badge bg-danger">Deactive</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => editUser(item)}
                              className="btn btn-primary btn-sm"
                            >
                              <BsFillPencilFill /> Edit
                            </button>

                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              onClick={() => deleteModalShow(item?.id)}
                              className="btn btn-danger ms-1 btn-sm"
                            >
                              <BsFillTrashFill /> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Users Added</p>
              </div>
            )
          ) : (
            <div className="loaderbox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
      <ModalBox
        show={isModalShow}
        size="lg"
        hide={modalClose}
        title={userUpdate.isUpdating ? "Edit User" : "Add User"}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Select Role</label>
                <select
                  className="form-select"
                  {...register("role", { required: !userUpdate.isUpdating })}
                  disabled={isLoading}
                >
                  <option value="">Select Role</option>
                  {roleList
                    .filter((role) => {
                      return role.status === "Active";
                    })
                    .map((role) => {
                      return (
                        <option value={role.id} key={role.id}>
                          {role.role_name}
                        </option>
                      );
                    })}
                </select>
                {errors.role && (
                  <div className="error_line">Role is required</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("name", { required: !userUpdate.isUpdating })}
                  disabled={isLoading}
                />
                {errors.name && (
                  <div className="error_line">Name is required</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  {...register("email_id", {
                    required: !userUpdate.isUpdating,
                  })}
                  disabled={isLoading}
                />
                {errors.email_id && (
                  <div className="error_line">Email is required</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("phone", {
                    required: {
                      value: !userUpdate.isUpdating,
                      message: "Phone is required",
                    },
                    maxLength: {
                      value: 10,
                      message: "Please enter 10 digit phone number",
                    },
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={isLoading}
                />
                {errors.phone && (
                  <div className="error_line">{errors.phone.message}</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Select State</label>
                <select
                  className="form-select"
                  {...register("state", {
                    required: !userUpdate.isUpdating,
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select State</option>
                  {stateList
                    .filter((state) => {
                      return state.status === "Active";
                    })
                    .map((state) => {
                      return (
                        <option value={state.id} key={state.id}>
                          {state.state_name}
                        </option>
                      );
                    })}
                </select>
                {errors.state && (
                  <div className="error_line">State is required</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Select District</label>
                <select
                  className="form-select"
                  {...register("district", {
                    required: !userUpdate.isUpdating,
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select District</option>
                  {cityList
                    .filter((cities) => {
                      return cities.status === "Active";
                    })
                    .map((cities) => {
                      return (
                        <option value={cities.id} key={cities.id}>
                          {cities.district_name}
                        </option>
                      );
                    })}
                </select>
                {errors.district && (
                  <div className="error_line">District Name is required</div>
                )}
              </div>
              {/* <div className="col-sm-6 col-md-4">
                  <label className="form-label">Select Block</label>
                  <select
                    className="form-select"
                    {...register("block", { required: !userUpdate.isUpdating })}
                    disabled={isLoading}
                  >
                    <option value="">Select Block</option>
                    {blockList
                      .filter((block) => {
                        return block.status === "Active";
                      })
                      .map((block) => {
                        return (
                          <option value={block.id} key={block.id}>
                            {block.block}
                          </option>
                        );
                      })}
                  </select>
                  {errors.block && (
                    <div className="error_line">Block is required</div>
                  )}
                </div> */}

              <div className="col-sm-6 col-md-4">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                  {...register("password", {
                    required: !userUpdate.isUpdating,
                  })}
                  disabled={isLoading}
                />
                {errors.password && (
                  <div className="error_line">Password is required</div>
                )}
              </div>
              <div className="col-sm-12 col-md-4">
                <label className="form-label">Status</label>
                <select
                  disabled={isLoading}
                  className="form-select"
                  {...register("status", {
                    required: !userUpdate.isUpdating,
                  })}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Deactive</option>
                </select>
                {errors.status && (
                  <div className="error_line">Status is required</div>
                )}
              </div>

              <div className="col-4">
                <div>
                  <label className="form-label"></label>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {userUpdate.isUpdating ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
      <ModalBox
        show={isDeleteModalShow}
        hide={deleteModalClose}
        title="Delete Role"
      >
        <div>
          <div className="mb-3">
            <p className="h5 text-dark">
              Are you sure you want to delete this user?
            </p>
          </div>
          <div className="d-flex gap-2">
            <button
              onClick={removeUser}
              type="button"
              className="btn btn-danger"
              disabled={isLoading}
            >
              Delete
            </button>
            <button
              onClick={deleteModalClose}
              type="button"
              className="btn btn-secondary"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBox>
    </>
  );
};

export default ManageUser;
