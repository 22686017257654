import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { FiDownload } from "react-icons/fi";
// import InfiniteScroll from "react-infinite-scroller";
// import qs from "qs";
// import Pagination from "react-bootstrap/Pagination";
import Table from "../Widget/Table";
import Loader from "../Widget/Loader";
import dayjs from "dayjs";

const DownloadForm = () => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  //   control,
  // } = useForm();

  const [application, setApplication] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  //get getDownloadedList
  const getDownloadedList = async () => {
    const res = await Api(`get-downloaded-sdrf-form`);
    // console.log(res);
    if (res?.data?.status === 1) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setApplication(res?.data?.data);
      setIsDataLoaded(true);
      // setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // setIsLoading(false);
    }
  };
  //get getDownloadedList

  useEffect(() => {
    getDownloadedList();
  }, []);

  //pagination count
  // const IndexCount = ({ index }) => {
  //   let cp = application.current_page;
  //   let pp = application.per_page;
  //   let count;
  //   if (cp > 1) {
  //     count = (cp - 1) * pp + index;
  //   } else {
  //     count = index;
  //   }
  //   return count;
  // };
  //pagination count

  //pagination
  // const PaginationRow = () => {
  //   return (
  //     <div className="mt-4 d-flex justify-content-md-center overflow-auto">
  //       <Pagination>
  //         {application?.links?.map((item, i) => {
  //           if (i === 0) {
  //             return (
  //               <Pagination.Prev
  //                 key={i}
  //                 onClick={() =>
  //                   getDownloadedList(application?.current_page - 1)
  //                 }
  //                 disabled={application?.current_page === 1 ? true : false}
  //               />
  //             );
  //           } else if (item.label === "...") {
  //             return <Pagination.Ellipsis key={i} />;
  //           } else if (i + 1 === application?.links.length) {
  //             return (
  //               <Pagination.Next
  //                 key={i}
  //                 onClick={() =>
  //                   getDownloadedList(application?.current_page + 1)
  //                 }
  //                 disabled={
  //                   application?.current_page === application?.last_page
  //                     ? true
  //                     : false
  //                 }
  //               />
  //             );
  //           } else if (i !== application?.links.length) {
  //             return (
  //               <Pagination.Item
  //                 key={i}
  //                 active={Number(item.label) === application?.current_page}
  //                 onClick={() => getDownloadedList(item.label)}
  //               >
  //                 {item?.label}
  //               </Pagination.Item>
  //             );
  //           } else {
  //             return false;
  //           }
  //         })}
  //       </Pagination>
  //     </div>
  //   );
  // };
  //pagination

  const columns = [
    {
      name: "#",
      selector: (props) => application.indexOf(props) + 1,
      sortable: true,
      width:'70px'
    },
    {
      name: "Application ID",
      selector: (row) => row.application_no,
      sortable: true,
      wrap: true,
    },
    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.user.email_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.user_ip,
      sortable: true,
      wrap: true,
    },
    {
      name: "Download Date",
      selector: (row) => dayjs(row.created_at).format('DD MMM YYYY hh:mm A'),
      sortable: true,
      wrap: true,
    },
    {
      name: "User Type",
      selector: (row) => (row.user?.role === "1" ? "Admin" : "User"),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-md-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Downloaded SDRF Forms</h5>
          </div>
          <div className="flex-shrink-0">
            <a
              href="https://nsdmautilityforms.org/"
              rel="noreferrer"
              target="_blank"
              className="btn btn-success btn-sm"
            >
              <FiDownload className="me-2" />
              Download Form
            </a>
          </div>
        </div>

        <div className="mt-3">
          {isDataLoaded ? (
            <Table columns={columns} data={application} />
          ) : (
            <Loader />
          )}
          {/* {!isLoading ? (
            application?.data?.length > 0 ? (
              <>
                <div className="table-responsive mt-3">
                  <table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Application Number</th>
                        <th>District</th>
                        <th>Email</th>
                        <th>IP Address</th>
                        <th>Download Date</th>
                        <th>User Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {application?.data?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <IndexCount index={i + 1} />
                            </td>
                            <td>{item?.application_no}</td>
                            <td>{item?.district?.district_name}</td>
                            <td>{item?.user.email_id}</td>
                            <td>{item?.user_ip}</td>
                            <td>{item?.created_at}</td>
                            <td>{item?.user?.role === "1" ? "Admin" : "User"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {application?.last_page > 1 && <PaginationRow />}
              </>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Downloaded List</p>
              </div>
            )
          ) : (
            <div className={`loaderoverlay show`}>
              <div>
                <img src={GifImg} alt="Loader" />
                <p>Loading...</p>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default DownloadForm;
