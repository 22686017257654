import React, { useEffect, useState } from "react";
import UserIcon from "../../assets/images/user.png";
import { useDispatch, useSelector } from "react-redux";
import { removeAuth } from "../../Reducers/Authentication";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FaCog, FaPowerOff, FaLock, FaMoon, FaSun } from "react-icons/fa";
import { RiMenu2Line } from "react-icons/ri";
import logo from "../../assets/images/logo.png";

const Header = ({ toggleBarBtn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(removeAuth());
    navigate("/login");
  };
  const admin = useSelector((state) => state.authentication.admin);

  const [adminData, setAdminData] = useState("");

  const fetchData = () => {
    let tempObj = JSON.parse(admin);
    setAdminData(tempObj);
  };

  useEffect(() => {
    if (admin) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  // Theme Toggle
  const [isDark, setIsDark] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  useEffect(() => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  }, []);

  const toggleThemeChange = () => {
    if (isDark === false) {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(true);
    } else {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(false);
    }
  };
  // Theme Toggle

  //zoom button
  // const [scaleState, setScaleState] = useState(1);
  // const zoomPlus = () => {
  //   if (scaleState <= 1.3) {
  //     setScaleState(scaleState + 0.1);
  //   }
  // };
  // const zoomMinus = () => {
  //   if (scaleState >= 0.8) {
  //     setScaleState(scaleState - 0.1);
  //   }
  // };
  // const zoomEqual = () => {
  //   setScaleState(1);
  // };

  // document.body.style.zoom = scaleState;
  //zoom button

  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
  }, []);

  return (
    <>
      <div className="adminheader">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="adminheadermenubtn">
              <button type="button" onClick={toggleBarBtn}>
                <RiMenu2Line />
              </button>
            </div>
            <div className="logo py-2 d-md-none">
              <img src={logo} style={{ height: "50px" }} alt="logo" />
            </div>
            {/* <div className="adminlanguagechangediv me-3 d-none d-sm-block">
              <div id="google_translate_element"></div>
            </div> */}
            <div className="d-none d-sm-block">
              <div className="fw-bold lh-sm text-dark">{time}</div>
            </div>
          </div>

          <div className="admintopright">
            <ul>
              {/* <li>
                <div className="themetoggle">
                  <input
                    type="checkbox"
                    id="themecheckbox"
                    onChange={toggleThemeChange}
                    defaultChecked={isDark}
                  />
                  <label htmlFor="themecheckbox">
                    <FaMoon className="moon" />
                    <FaSun className="sun" />
                    <span></span>
                  </label>
                </div>
              </li> */}
              <li>
                <div
                  className={`themetoggle ${isDark ? "darkbtn" : "lightbtn"}`}
                >
                  <button type="button" onClick={toggleThemeChange}>
                    {isDark ? (
                      <FaMoon className="moon" />
                    ) : (
                      <FaSun className="sun" />
                    )}
                  </button>
                </div>
              </li>
              <li>
                <Dropdown className="admintoprightdropdown">
                  <Dropdown.Toggle
                    variant=""
                    bsPrefix="d-flex align-items-center"
                  >
                    <img
                      src={adminData?.logo ? adminData?.logo : UserIcon}
                      className="user-img"
                      alt="avatar"
                    />
                    <span className="activedot"></span>
                    {/* <div className="user-info px-lg-2 d-none d-lg-inline-block">
                      <p className="user-name mb-0">
                        {adminData?.name ? adminData?.name : "Admin"}
                      </p>
                    </div>
                    <FaChevronDown className="d-none d-lg-inline-block" /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item as={Link} to="/change-information">
                      <FaCog />
                      Change Information
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/change-password">
                      <FaLock />
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleLogout}>
                      <FaPowerOff />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
