import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { useForm, Controller } from "react-hook-form";
// import { Modal } from "react-bootstrap";
import GifImg from "../../assets/images/loader.gif";
import Select from "react-select";
import { toast } from "react-toastify";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import Permissions from "./Components/Permission.json";
import ModalBox from "../Widget/ModalBox";
const ManageRole = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const [roleList, setRoleList] = useState([]);
  const [permission] = useState(Permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roleUpdate, setRoleUpdate] = useState({
    id: "",
    isUpdating: false,
  });

  const modalClose = () => {
    setIsModalShow(false);
    reset({
      role: "",
      access_level: "",
      status: "",
    });
    setRoleUpdate({ id: "", isUpdating: false });
  };
  const modalShow = () => setIsModalShow(true);

  const deleteModalClose = () => {
    setIsDeleteModalShow(false);
    setDeleteId("");
  };
  const deleteModalShow = (id) => {
    setDeleteId(id);
    setIsDeleteModalShow(true);
  };

  //get roles
  const getRoles = async () => {
    setIsLoading(true);
    const res = await Api("get-role");
    if (res?.data?.status === 1) {
      setRoleList(res?.data?.roleDetail.reverse());
      setIsLoading(false);
      setIsDataLoaded(true);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get roles

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #4f46e5" : "1px solid #ced4da",
      boxShadow: "none",
    }),
  };

  //Update Role
  const editRole = async (roleObj) => {
    modalShow();
    setRoleUpdate({ id: roleObj.id, isUpdating: true });
    const temparr = await roleObj?.permission?.split(", ");
    // console.log(roleObj);
    const tempobj = temparr?.map((a) => {
      return {
        label: a,
        value: a,
      };
    });
    reset({
      role: roleObj.role_name,
      permission: tempobj,
      status: roleObj.status,
    });
  };
  //Update Role

  //Delete Role
  const removeRole = async () => {
    try {
      setIsLoading(true);
      const body = {
        id: deleteId,
      };
      const res = await Api("delete-role", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        toast.success("Role Deleted Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteId("");
        getRoles();
        deleteModalClose();
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete Role

  useEffect(() => {
    getRoles();
  }, []);

  const onSubmit = async (d) => {
    setIsLoading(true);
    if (roleUpdate.isUpdating) {
      const body = {
        role_name: d.role,
        permission: d.permission.map((item) => item.value),
        status: d.status,
      };
      const res = await Api(`update-role/${roleUpdate.id}`, "POST", body);
      // console.log(res);
      if (res?.data?.status === 1) {
        toast.success("Role Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        setIsLoading(false);
        modalClose();
        setRoleUpdate({ id: "", isUpdating: false });
        getRoles();
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } else {
      const body = {
        role_name: d.role,
        permission: d.permission.map((item) => item.value),
        status: d.status,
      };
      const res = await Api("add-role", "POST", body);
      // console.log(res);
      if (res?.data?.status === 1) {
        toast.success("Role Added Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        setIsLoading(false);
        modalClose();
        getRoles();
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="bg-white shadow-sm p-3">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage Roles</h5>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={modalShow}
            >
              <BsPlusLg className="me-2" />
              Add Role
            </button>
          </div>
        </div>
        <div className="mt-3">
          {isDataLoaded ? (
            roleList.length > 0 ? (
              <div className="table-responsive mt-3">
                <table className="table table-bordered m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role Name</th>
                      <th style={{ width: "50%" }}>Permission</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.role_name}</td>
                          <td>{item.permission}</td>
                          <td>
                            {item?.status === "Active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : item.status === "Deactive" ? (
                              <span className="badge bg-danger">Deactive</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                              onClick={() => editRole(item)}
                              className="btn btn-primary btn-sm"
                            >
                              <BsFillPencilFill /> Edit
                            </button>
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              onClick={() => deleteModalShow(item?.id)}
                              className="btn btn-danger ms-1 btn-sm"
                            >
                              <BsFillTrashFill /> Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Roles Added</p>
              </div>
            )
          ) : (
            <div className="loaderbox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>

      <ModalBox
        show={isDeleteModalShow}
        hide={deleteModalClose}
        title="Delete Role"
      >
        <div>
          <div className="mb-3">
            <p className="h5 text-dark">
              Are you sure you want to delete this role?
            </p>
          </div>
          <div className="d-flex gap-2">
            <button
              onClick={removeRole}
              type="button"
              className="btn btn-danger"
              disabled={isLoading}
            >
              Delete
            </button>
            <button
              onClick={deleteModalClose}
              type="button"
              className="btn btn-secondary"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBox>
      <ModalBox
        show={isModalShow}
        hide={modalClose}
        title={roleUpdate.isUpdating ? "Edit Role" : "Add Role"}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-12">
                <div>
                  <label className="form-label">Role Name</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={isLoading}
                    {...register("role", { required: true })}
                  />
                  {errors.role && (
                    <div className="error_line">Role is required</div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div>
                  <label className="form-label">Permission</label>
                  <div>
                    <Controller
                      name="permission"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={"Select Permission"}
                          options={permission}
                          isMulti
                          isDisabled={isLoading}
                          styles={customStyles}
                          closeMenuOnSelect={false}
                          onChange={(d) => {
                            field.onChange(d);
                          }}
                        />
                      )}
                    />
                    {errors.permission && (
                      <div className="error_line">Permission is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div>
                  <label className="form-label">Select Status</label>
                  <select
                    disabled={isLoading}
                    className="form-select"
                    {...register("status", { required: true })}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="">
                  <button
                    disabled={isLoading}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {roleUpdate.isUpdating ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
    </>
  );
};

export default ManageRole;
