import React from "react";
import { Route } from "react-router-dom";

import DownloadForm from "../Views/DownloadForm/";
import AddCenter from "../Views/Center/AddCenter";
import ManageCenter from "../Views/Center/ManageCenter";
import ManageState from "../Views/Master/ManageState";
import ManageDistrict from "../Views/Master/ManageDistrict";
// import ManageBlock from "../Views/Master/ManageBlock";
import ManageRole from "../Views/User/ManageRole";
import ManageUser from "../Views/User/ManageUser";
import PaymentHistory from "../Views/PaymentHistory";

const useRoute = (permission) => {
  const accessRoutes = [];
  const accessRouteHome = [];

  if (permission) {
    let filterAccessRoutes = permission.map((a, i) => {
      if (a === "Download Form") {
        return <Route path="download-form" element={<DownloadForm />} />;
      } else if (a === "Manage Roles") {
        return <Route path="manage-role" element={<ManageRole />} />;
      } else if (a === "Manage Users") {
        return <Route path="manage-user" element={<ManageUser />} />;
      } else if (a === "Manage Centers") {
        return (
          <>
            <Route path="add-center" element={<AddCenter />} />
            <Route path="manage-center" element={<ManageCenter />} />
          </>
        );
      } else if (a === "Manage States") {
        return <Route path="manage-state" element={<ManageState />} />;
      } else if (a === "Manage Districts") {
        return <Route path="manage-district" element={<ManageDistrict />} />;
      }
      // else if (a === "Manage Blocks") {
      //   return <Route path="manage-block" element={<ManageBlock />} />;
      // }
      else if (a === "Payment History") {
        return <Route path="payment-history" element={<PaymentHistory />} />;
      } else {
        return true;
      }
    });

    accessRoutes.push(filterAccessRoutes);
    return { accessRoutes, accessRouteHome };
  }

  return "";
};

export default useRoute;
