import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TooltipWidget = ({ title, placement = "top", children }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{title}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
};

export default TooltipWidget;
