import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import GifImg from "../../assets/images/loader.gif";
import { toast } from "react-toastify";

const ManageState = () => {
  const [stateList, setStateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //get state
  const getStates = async () => {
    setIsLoading(true);
    const res = await Api("get-state");
    // console.log(res);
    if (res?.data?.status === 1) {
      setStateList(res?.data?.states.reverse());
      setIsLoading(false);
    }else{
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStates();
  }, []);
  //get state

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage States</h5>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className="loaderbox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          ) : stateList.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>State Name</th>
                    <th>State Code</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {stateList.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.state_name}</td>
                        <td>{item.state_code}</td>
                        <td>
                          {item.status === "Active" ? (
                            <span className="badge bg-success">Active</span>
                          ) : item.status === "Deactive" ? (
                            <span className="badge bg-danger">Deactive</span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-danger m-0">
              <p>No States Added</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageState;
