import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Reducers/Authentication";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const admin = useSelector((state) => state.authentication.admin);
  const adminToken = useSelector((state) => state.authentication.adminToken);
  const [adminData, setAdminData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    let tempObj = JSON.parse(admin);
    setAdminData(tempObj);
  };

  useEffect(() => {
    if (admin) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  useEffect(() => {
    // console.log(adminData);
    if (!!adminData) {
      reset({
        role: adminData.role?.role_name,
        name: adminData.full_name,
        email: adminData.email_id,
        phone: adminData.mobile_no,
        state: adminData.state?.state_name,
        district: adminData.city?.district_name,
      });
    }
  }, [adminData, reset]);

  const dispatch = useDispatch();

  const onSubmit = async (d) => {
    // console.log(d);
    setIsLoading(true);
    const body = {
      name: d.name,
      email: d.email,
      phone: d.phone,
    };
    const res = await Api("user-change-info", "POST", body);
    // console.log(res);

    if (res.data.status === 1) {
      toast.success("Data Updated Successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      let obj1 = adminData;
      let obj2 = res.data.UserDetails;
      let obj3 = { token: JSON.parse(adminToken) };
      let obj4 = Object.assign(obj1, obj2, obj3);
      dispatch(setAuth(obj4));
      setIsLoading(false);
    } else {
      toast.error(res.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Change Information</h5>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="row g-3">
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Role</label>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  {...register("role")}
                />
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  disabled={isLoading}
                  className="form-control"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <div className="error_line">Name is required</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  disabled={isLoading}
                  className="form-control"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <div className="error_line">Email is required</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Phone</label>
                <input
                  type="text"
                  disabled={isLoading}
                  className="form-control"
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "Phone is required",
                    },
                    maxLength: {
                      value: 10,
                      message: "Please enter 10 digit phone number",
                    },
                  })}
                />
                {errors.phone && (
                  <div className="error_line">{errors.phone.message}</div>
                )}
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">State</label>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  {...register("state")}
                />
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">District</label>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  {...register("district")}
                />
              </div>
              <div className="col-4">
                <div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Index;
