import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from "../../config/Api";
import GifImg from "../../assets/images/loader.gif";
//import { useForm, Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FaCog, FaMapMarkerAlt } from "react-icons/fa";
import GoogleMap from "./Components/GoogleMap";
//import Select from "react-select";
const AddCenter = () => {
  const {
    //control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    // setValue,
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};

  const [mapModal, setMapModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [centerUpdating, setCenterUpdating] = useState({
    id: "",
    isUpdating: false,
  });
  //const [permissionList, setPermissionList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const [blockList, setBlockList] = useState([]);

  const mapModalShow = () => {
    setMapModal(true);
  };

  const mapModalClose = () => {
    setMapModal(false);
  };
  // const style = {
  //   control: (base, state) => ({
  //     ...base,
  //     border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
  //     boxShadow: "none",
  //     backgroundColor: state.isFocused ? "" : "#fbfbfb",
  //     "&:hover": {
  //       borderColor: "#224b8b",
  //     },
  //   }),
  //   option: (base, state) => ({
  //     ...base,
  //     backgroundColor: state.isSelected ? "#224b8b" : "",
  //     "&:hover": {
  //       color: "#fff",
  //       backgroundColor: "#224b8b",
  //     },
  //   }),
  // };
  // const optionType = (d) => {
  //   return d.map((a) => {
  //     return {
  //       label: a.name,
  //       value: a.name,
  //     };
  //   });
  // };

  //get permission
  // const getPermission = async () => {
  //   try {
  //     setIsLoading(true);
  //     const res = await Api("permission_list");
  //     // console.log(res);
  //     if (res?.data?.status === 1) {
  //       // setPermissionList(optionType(res?.data?.data));
  //       setPermissionList(optionType(res?.data?.data));
  //       setIsLoading(false);
  //     } else {
  //       toast.error(res?.data?.message, {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //       });
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     toast.error(error.data.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //   }
  // };
  //get permission

  //get state
  const getStates = async () => {
    setIsLoading(true);
    const res = await Api("get-state");
    // console.log(res);
    if (res?.data?.status === 1) {
      setStateList(res?.data?.states);
      setIsLoading(false);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };
  //get state

  //get Cities
  const getCities = async (id) => {
    setIsLoading(true);
    const res = await Api(`get-city/${id}`);
    //  console.log(res);
    if (res?.data?.status === 1) {
      setCityList(res?.data?.districts);
      setIsLoading(false);
      return true;
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      return true;
    }
  };
  //get cities

  //get Blocks
  // const getBlocks = async (id) => {
  //   setIsLoading(true);
  //   const res = await Api(`get-block/${id}`);
  //   if (res?.data?.status === 1) {
  //     setBlockList(res.data.data);
  //     setIsLoading(false);
  //     return true;
  //   } else {
  //     toast.error(res?.data?.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //     setIsLoading(false);
  //     return true;
  //   }
  // };
  //get Blocks

  const inputChange = async (e) => {
    if (e.target.value) {
      if (e.target.name === "state") {
        getCities(e.target.value);
        reset({
          ...getValues(),
          district: "",
          block: "",
        });
      }
      //  else if (e.target.name === "district") {
      //   getBlocks(e.target.value);
      //   setValue("block", "");
      // }
    }
  };
  //get Block

  useEffect(() => {
    getStates();
    // getPermission();
  }, []);

  // const DropDown = (props) => {
  //   const options = props.multi
  //     ? [{ label: "Select All", value: "all" }, ...props.options]
  //     : props.options;

  //   return (
  //     <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
  //       <Controller
  //         control={control}
  //         name="permission"
  //         rules={{ required: true }}
  //         render={({ field }) => (
  //           <Select
  //             {...field}
  //             styles={style}
  //             placeholder={"Select Permission"}
  //             options={options}
  //             // closeMenuOnSelect={false}
  //             isDisabled={isLoading}
  //             isMulti
  //             value={field.value ? field.value : null}
  //             onChange={(selected) => {
  //               // field.onChange(selected);
  //               props.multi &&
  //               selected.length &&
  //               selected.find((option) => option.value === "all")
  //                 ? field.onChange(options.slice(1))
  //                 : !props.multi
  //                 ? field.onChange((selected && selected.value) || null)
  //                 : field.onChange(selected);
  //             }}
  //           />
  //         )}
  //       />
  //     </div>
  //   );
  // };

  useEffect(() => {
    // console.log(data);
    if (!!data) {
      // console.log(data);
      setCenterUpdating({ id: data?.id, isUpdating: true });
      (async () => {
        const res1 = await getCities(data?.state_id);
        // const res2 = await getBlocks(data?.district_id);
        // console.log(res1, res2);

        // let tempPermission = data?.permission?.map((a) => {
        //   return { label: a, value: a };
        // });
        if (res1) {
          reset({
            center_name: data?.center_name,
            center_email: data?.center_email,
            // permission: tempPermission,
            name: data?.name,
            phone: data?.phone,
            email: data?.email,
            address: data?.address,
            state: data?.state_id,
            district: data?.district_id,
            // block: data?.block_id,
            username: data?.username,
            status: data?.status,
          });
        }
      })();
    }
  }, [data, reset]);

  const onSubmit = async (d) => {
    setIsLoading(true);
    if (centerUpdating.isUpdating) {
      if (!!d.password || !!d.confirm_password) {
        if (!d.password || !d.confirm_password) {
          toast.error("Password & Confirm Password  Is Required", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
          return;
        }
        if (d.password !== d.confirm_password) {
          toast.error("Password & Confirm Password Not Matching", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
          return;
        }
      }
      const body = {
        center_name: d.center_name,
        center_email: d.center_email,
        name: d.name,
        phone: d.phone,
        email: d.email,
        address: d.address,
        state_id: d.state,
        district_id: d.district,
        username: d.username,
        password: d.confirm_password,
        status: d.status,
      };

      Object.keys(body).forEach(
        (k) => (body[k] === null || body[k] === "") && delete body[k]
      );

      // console.log(centerUpdating.id);
      // console.log(body);

      const res = await Api(`update-center/${centerUpdating.id}`, "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        // setCenterUpdating({ id: '', isUpdating: false });
        toast.success("Center Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        navigate("/manage-center");
        setIsLoading(false);
      } else {
        // console.log("else");
        if (
          res?.data?.message?.center_email?.length &&
          res?.data?.message?.center_email[0]
        ) {
          toast.error(res?.data?.message?.center_email[0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if (
          res?.data?.message?.email?.length &&
          res?.data?.message?.email[0]
        ) {
          toast.error(res?.data?.message?.email[0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if (
          res?.data?.message?.username?.length &&
          res?.data?.message?.username[0]
        ) {
          toast.error(res?.data?.message?.username[0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("something went wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setIsLoading(false);
      }
    } else {
      if (d.password === d.confirm_password) {
        const body = {
          center_name: d.center_name,
          center_email: d.center_email,
          name: d.name,
          phone: d.phone,
          email: d.email,
          address: d.address,
          state_id: d.state,
          district_id: d.district,
          username: d.username,
          password: d.confirm_password,
          status: d.status,
        };
        // console.log(body);
        // console.log('form body',body);
        const res = await Api("add-center", "POST", body);
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Center Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          navigate("/manage-center");
          setIsLoading(false);
        } else {
          if (
            res?.data?.message?.center_email?.length &&
            res?.data?.message?.center_email[0]
          ) {
            toast.error(res?.data?.message?.center_email[0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (
            res?.data?.message?.email?.length &&
            res?.data?.message?.email[0]
          ) {
            toast.error(res?.data?.message?.email[0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (
            res?.data?.message?.username?.length &&
            res?.data?.message?.username[0]
          ) {
            toast.error(res?.data?.message?.username[0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("something went wrong!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setIsLoading(false);
        }
      } else {
        toast.error("Password & Confirm Password Not Matching", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className={`loaderoverlay ${isLoading && "show"}`}>
        <img src={GifImg} alt="Loader" />
      </div>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-sm-flex text-center text-sm-start justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Add Center</h5>
          </div>
          <div className="flex-shrink-0">
            <Link className="btn btn-primary btn-sm" to="/manage-center">
              <FaCog className="me-2" />
              Manage Centers
            </Link>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-lg-12">
                <h6 className="text-dark m-0">Center Detail</h6>
              </div>
              <div className="col-lg-4">
                <label className="form-label">Center Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Center Name"
                  {...register("center_name", {
                    required: true,
                  })}
                  disabled={isLoading}
                />
                {errors.center_name && (
                  <div className="error_line">Center Name is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Center Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Center Email"
                  {...register("center_email", {
                    required: "Center Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.center_email && (
                  <div className="error_line">
                    {errors.center_email.message}
                  </div>
                )}
              </div>
              {/* <div className="col-lg-4">
                <div className="">
                  <label className="form-label">Permission</label>
                  <DropDown options={permissionList} multi={true} />

                  {errors.permission && (
                    <div className="error_line">Permission is required</div>
                  )}
                </div>
              </div> */}

              <div className="col-lg-12">
                <hr className="m-0" />
              </div>
              <div className="col-lg-12">
                <h6 className="text-dark m-0">Authorised Person Detail</h6>
              </div>
              <div className="col-lg-4">
                <label className="form-label"> Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  {...register("name", {
                    required: true,
                  })}
                  disabled={isLoading}
                />
                {errors.name && (
                  <div className="error_line">Name is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Enter Phone"
                  {...register("phone", {
                    required: true,
                  })}
                  disabled={isLoading}
                />
                {errors.phone && (
                  <div className="error_line">Phone is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.email && (
                  <div className="error_line">{errors.email.message}</div>
                )}
              </div>

              <div className="col-lg-12">
                <hr className="m-0" />
              </div>
              <div className="col-lg-12">
                <h6 className="text-dark m-0">Address</h6>
              </div>
              <div className="col-lg-4">
                <label className="form-label">Address</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control pe-5"
                    placeholder="Enter Address"
                    {...register("address", {
                      required: true,
                    })}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    className="markerbtn"
                    onClick={mapModalShow}
                  >
                    <FaMapMarkerAlt />
                  </button>
                </div>
                {errors.address && (
                  <div className="error_line">Address is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">State</label>

                <select
                  className="form-select"
                  {...register("state", {
                    required: true,
                    onChange: (e) => inputChange(e),
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select State</option>
                  {stateList
                    .filter((state) => {
                      return state.status === "Active";
                    })
                    .map((state) => {
                      return (
                        <option value={state.id} key={state.id}>
                          {state.state_name}
                        </option>
                      );
                    })}
                </select>
                {errors.state && (
                  <div className="error_line">State is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">District</label>
                <select
                  className="form-select"
                  {...register("district", {
                    required: true,
                    onChange: (e) => {
                      inputChange(e);
                    },
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select District</option>
                  {cityList
                    .filter((cities) => {
                      return cities.status === "Active";
                    })
                    .map((cities) => {
                      return (
                        <option value={cities.id} key={cities.id}>
                          {cities.district_name}
                        </option>
                      );
                    })}
                </select>
                {errors.district && (
                  <div className="error_line">District is required</div>
                )}
              </div>
              {/* <div className="col-lg-3">
                <label className="form-label">Block</label>
                <select
                  className="form-select"
                  {...register("block", {
                    required: true,
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select Block</option>
                  {blockList
                    .filter((blocks) => {
                      return blocks.status === "Active";
                    })
                    .map((blocks) => {
                      return (
                        <option value={blocks.id} key={blocks.id}>
                          {blocks.block}
                        </option>
                      );
                    })}
                </select>
                {errors.block && (
                  <div className="error_line">Block is required</div>
                )}
              </div> */}
              <div className="col-lg-12">
                <hr className="m-0" />
              </div>
              <div className="col-lg-12">
                <h6 className="text-dark m-0">Login Credentials</h6>
              </div>
              <div className="col-lg-4">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Username"
                  {...register("username", {
                    required: true,
                  })}
                  disabled={isLoading}
                />
                {errors.username && (
                  <div className="error_line">Username is required</div>
                )}
                <div></div>
              </div>
              <div className="col-lg-4">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                  {...register("password", {
                    required: !centerUpdating.isUpdating,
                  })}
                  disabled={isLoading}
                  autoComplete="new-password"
                />
                {errors.password && (
                  <div className="error_line">Password is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                  {...register("confirm_password", {
                    required: !centerUpdating.isUpdating,
                  })}
                  disabled={isLoading}
                  autoComplete="new-password"
                />
                {errors.confirm_password && (
                  <div className="error_line">Confirm Password is required</div>
                )}
              </div>
              <div className="col-lg-12">
                <hr className="m-0" />
              </div>
              <div className="col-lg-4">
                <label className="form-label">Status</label>
                <select
                  className="form-select"
                  {...register("status", {
                    required: true,
                  })}
                  disabled={isLoading}
                >
                  <option value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                </select>
                {errors.status && (
                  <div className="error_line">Status is required</div>
                )}
              </div>

              <div className="col-lg-2">
                <div>
                  <label className="form-label d-block"></label>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary"
                  >
                    {centerUpdating.isUpdating ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal
        size="lg"
        show={mapModal}
        onHide={mapModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Select Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <GoogleMap reset={reset} modalClose={mapModalClose} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCenter;
