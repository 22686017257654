import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels";
// import Api from "../../config/Api";
// import { BsDownload } from "react-icons/bs";
import {
  FaHourglassStart,
  FaHourglassHalf,
  FaCheckDouble,
  FaRupeeSign,
  FaTimes,
  FaCheckSquare,
} from "react-icons/fa";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const Dashboard = () => {
  const [counters, setCounters] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [barData, setBarData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [pieData, setPieData] = useState([]);

  const getDistrict = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api("get-city/1");
      //console.log(data);
      if (data?.status === 1) {
        setDistrictList(data?.districts);
        setIsLoading(false);
        return true;
      } else {
        toast.error(data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const getCounters = async () => {
    const res = await Api("dashboard");
    // console.log(res["data"]["data"][0]);
    if (res.data.status === 1) {
      setCounters(res.data.data[0]);
    }
  };
  const getBardata = async (did) => {
    const body = {};
    if (Boolean(did)) {
      body.district_id = did;
    }
    const { data } = await Api("linechart", "POST", body);
    // console.log(data);
    if (data.status === 1) {
      setBarData(data.data);
    }
  };
  const updateLineChart = (e) => {
    getBardata(e.target.value);
  };
  const getpieData = async () => {
    const { data } = await Api("piechart");
    // console.log(data);
    if (data.status === 1) {
      setPieData(data.data);
    }
  };
  useEffect(() => {
    getCounters();
    getDistrict();
    getBardata();
    getpieData();
  }, []);

  const labels = barData?.map((a) =>
    dayjs(a.month, "MMMM YYYY").format("MMM YYYY")
  );
  const dataCounts = barData?.map((a) => Number(a.number_of_applications));

  const pieLabels1 = pieData?.districts?.map((a) => a?.district_name);
  const pieCounts1 = pieData?.districts?.map((a) => a?.districts);
  const pieLabels3 = pieData?.itemsdamage?.map((a) => a?.title);
  const pieCounts3 = pieData?.itemsdamage?.map((a) => a?.No_of_first_damage);

  const object1 = pieData?.naturedisaster;

  var pieLabels2 = [];
  var pieCounts2 = [];
  if (object1 !== undefined) {
    for (const [key, value] of Object.entries(object1)) {
      // console.log(`${key}: ${value}`);
      pieLabels2.push(key);
      pieCounts2.push(value);
    }
  }
  //console.log(pieLabels1);
  const data1 = {
    labels: labels,
    datasets: [
      {
        data: dataCounts,
        backgroundColor: "rgba(162, 140, 233, .5)",
        barThickness: 8,
        borderColor: "transparent",
        borderRadius: 10,
        fill: "start",
      },
    ],
  };
  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data2 = {
    labels: pieLabels1,
    datasets: [
      {
        label: "",
        data: pieCounts1,
        backgroundColor: [
          "#4f46e5",
          "#10b981",
          "#ff9800",
          "#0ea5e9",
          "#f000b9",
          "#ff5724",
          "#4f46e5",
          "#10b981",
          "#ff9800",
          "#0ea5e9",
          "#f000b9",
          "#ff5724",
        ],
        borderWidth: 1,
      },
    ],
  };
  const data3 = {
    labels: pieLabels3,
    datasets: [
      {
        label: "",
        data: pieCounts3,
        backgroundColor: [
          "#4f46e5",
          "#10b981",
          "#ff9800",
          "#0ea5e9",
          "#f000b9",
          "#ff5724",
          "#4f46e5",
          "#10b981",
          "#ff9800",
          "#0ea5e9",
          "#f000b9",
          "#ff5724",
        ],
        borderWidth: 1,
      },
    ],
  };
  const data4 = {
    labels: pieLabels2,
    datasets: [
      {
        label: "",
        data: pieCounts2,
        backgroundColor: [
          "#4f46e5",
          "#10b981",
          "#ff9800",
          "#0ea5e9",
          "#f000b9",
          "#ff5724",
          "#4f46e5",
          "#10b981",
          "#ff9800",
          "#0ea5e9",
          "#f000b9",
          "#ff5724",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: true, color: "#fff" },
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <>
      <div className="mb-3">
        <div className="row g-3 counterboxrow">
          <div className="col-sm-6  col-lg-2">
            <div className="counterbox">
              <div className="d-flex">
                <div className="counterboxdata">
                  <p className="value">
                    {counters?.pending ? counters?.pending : 0}
                  </p>
                </div>
                <div className="counterboxicon">
                  <FaHourglassStart />
                </div>
              </div>
              <p className="label">Pending SDRF</p>
            </div>
          </div>
          <div className="col-sm-6  col-lg-2">
            <div className="counterbox">
              <div className="d-flex">
                <div className="counterboxdata">
                  <p className="value">
                    {counters?.rejected ? counters?.rejected : 0}
                  </p>
                </div>
                <div className="counterboxicon">
                  <FaTimes />
                </div>
              </div>
              <p className="label">Rejected SDRF</p>
            </div>
          </div>
          <div className="col-sm-6  col-lg-2">
            <div className="counterbox">
              <div className="d-flex">
                <div className="counterboxdata">
                  <p className="value">
                    {counters?.verified ? counters?.verified : 0}
                  </p>
                </div>
                <div className="counterboxicon">
                  <FaCheckSquare />
                </div>
              </div>
              <p className="label">Verified SDRF</p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-2">
            <div className="counterbox">
              <div className="d-flex">
                <div className="counterboxdata">
                  <p className="value">
                    {counters?.approved ? counters?.approved : 0}
                  </p>
                </div>
                <div className="counterboxicon">
                  <FaCheckDouble />
                </div>
              </div>
              <p className="label">Approved SDRF</p>
            </div>
          </div>
          <div className="col-sm-6  col-lg-2">
            <div className="counterbox">
              <div className="d-flex">
                <div className="counterboxdata">
                  <p className="value">
                    {counters?.under_process ? counters?.under_process : 0}
                  </p>
                </div>
                <div className="counterboxicon">
                  <FaHourglassHalf />
                </div>
              </div>
              <p className="label">Under Process SDRF</p>
            </div>
          </div>

          <div className="col-sm-6  col-lg-2">
            <div className="counterbox">
              <div className="d-flex">
                <div className="counterboxdata">
                  <p className="value">
                    {counters?.released ? counters?.released : 0}
                  </p>
                </div>
                <div className="counterboxicon">
                  <FaRupeeSign />
                </div>
              </div>
              <p className="label">Released SDRF</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 bg-white p-3 rounded shadow-sm">
        <div className="row g-3">
          <div className="col-lg-8">
            <h5 className="mb-3 text-dark">SDRF Status</h5>
          </div>
          <div className="col-lg-4">
            <div className="">
              <select
                className="form-select"
                onChange={updateLineChart}
                disabled={isLoading}
              >
                <option value="">All District</option>
                {districtList?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item?.district_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="bargraphbox mt-3">
          <Bar options={options1} data={data1} />
        </div>
      </div>

      <div>
        <div className="row g-3">
          <div className="col-lg-4">
            <div className="bg-white p-3 shadow-sm rounded-3">
              <h5 className="mb-3">SDRF Per District</h5>
              <div className="chartbox mt-3">
                <Doughnut options={options2} data={data2} />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-white p-3 shadow-sm rounded-3">
              <h5 className="mb-3">SDRF Nature Of disaster wise</h5>
              <div className="chartbox mt-3">
                <Doughnut options={options2} data={data4} />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-white p-3 shadow-sm rounded-3">
              <h5 className="mb-3">SDRF Item damaged wise</h5>
              <div className="chartbox mt-3">
                <Doughnut options={options2} data={data3} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
